import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Tabs = ({ data, deployment }) => {
  const location = useLocation();
  const split = location.pathname.split("/");
  const [activeTab, setActiveTab] = useState(`/${split[split.length - 1]}`);

  useEffect(() => {
    setActiveTab(`/${split[split.length - 1]}`);
  }, [location]);

  return (
    <>
      {/* overflow-x-auto scrollbar-hide */}
      <div className="flex whitespace-nowrap gap-2 border-b-2 overflow-x-scroll scrollbar-hide border-tertiary text-gray-500 relative -mt-2">
        {/* If product has modules and deployments */}
        {data?.map((item, id) => {
          return (
            <Link
              key={id}
              to={deployment === null ? "#": deployment + item.slug}
              className={`flex gap-2 items-center py-2 px-3 w-max rounded-t-md border-transparent  hover:shadow-sm hover:border-gray-300 ease-in-out ${
                activeTab === item.slug
                  ? "text-white bg-tertiary"
                  : "text-gray-400 hover:bg-gray-100 drop-shadow-sm bg-gray-50"
              } `}
            >
              <span>{item.icon}</span>
              <span className="text-xs font-semibold">{item.title}</span>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default Tabs;
