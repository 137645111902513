// ***************************************************************  All Power BI Dashboards  ***************************************************************

export const dashboards = {
  // Territory Design
  businessDashboards: {
    masterDashboard: {
      deployment2024: {
        reportId: "3bd69bcb-96ac-416a-a68f-bb79fb2be486",
      },
    },
    performanceDashboard: {
      deployment2024: {
        reportId: "bf130a38-5dbe-4d8e-9497-aa5adaca563c",
      },
    },
    foreqastTracking: {
      deployment2024: {
        reportId: "bd8aa512-3614-4338-b9ff-3e2d3ddc62b5",
      },
    },
    metricSummary: {
      deployment2024: {
        reportId: "7b937c6d-7443-4c67-a421-6aa2392e7e60",
      },
    },
    revenueDrillDown: {
      deployment2024: {
        reportId: "823e3301-a239-4168-bccc-4a91b4eb0c95",
      },
    },
    expenseDrillDown: {
      deployment2024: {
        reportId: "d4a98d9d-79a1-41fb-bb2d-ebedea90c3fd",
      },
    },
  },
};
