import React, { useState, useContext } from "react";
import { LuLoader2 } from "react-icons/lu";
import {
  CurrencyFormatter,
  NumberFormatter,
  PercentFormatter,
  PercentFormatter2,
} from "../../utils/Utils";
import {
  initial_model_input,
  inputScenarios,
  modelInputRows,
} from "../../../data/modules/equityModel";
import { EquityModelContext } from "../../../context/equityModelContext";

import Modal from "react-modal";
import generatePDF from "react-to-pdf";

// Modal.setAppElement('#root');

const InputScreen = ({
  handleBackspaceForPercentage,
  handlePercentageInputChange,
  handleCurrencyChange,
  handleNumberInputChange,
  handleDescriptionChange,
  submitForm,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const {
    modelInputs,
    setModelInputs,
    showOutput,
    setShowOutput,
    setSidebar,
    load,
    setCurrentOutputTab,
    setCurrentScenarioIndex,
    allOutputScenario,
    setOutputTypeToggle,
    showError,
  } = useContext(EquityModelContext);

  const customStyles = {
    content: {
      width: "80vw",
      height: "37vh",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-45%, -50%)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10,
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  // PDF Options
  const options = {
    filename: "Equity Model Inputs.pdf",
    page: {
      margin: 20,
    },
  };

  const getTargetElement = () =>
    document.getElementById("input-form-container");
  const downloadPDF = () => {
    setIsExport(true);
    setTimeout(() => {
      generatePDF(getTargetElement, options);
      setIsExport(false);
    }, 10);
  };

  return (
    <>
      <div className="flex items-center justify-between w-full top-16 border-b py-3 bg-white">
        <h3 className="font-bold">Model Input</h3>
        <div className="flex items-start gap-5">
          <div
            className="p-2 border rounded-md cursor-pointer bg-secondary"
            onClick={() => downloadPDF()}
          >
            Export PDF
          </div>
          <div
            className="p-2 border rounded-md cursor-pointer"
            onClick={() => setModelInputs(initial_model_input)}
          >
            Default Values
          </div>
          <div className="border-b-2 border-gray-400 p-1 text-xs italic text-gray-400">
            Inputs
          </div>
        </div>
      </div>

      <div className="border w-full rounded-md overflow-auto my-4 sticky">
        <div className="w-full px-4 my-4 flex justify-between">
          <div className="border w-full rounded-md overflow-auto max-h-[500px]">
            <table
              className="w-full text-xs relative"
              id="input-form-container"
            >
              <thead className="bg-gray-400 sticky top-0 w-full">
                <th className="text-left px-4 py-4 w-[40%]">Elements</th>
                <th className="text-center py-4">Baseline</th>
                <th className="text-center py-4">Scenario A</th>
                <th className="text-center py-4">Scenario B</th>
                <th className="text-center py-4">Scenario C</th>
              </thead>
              <tbody>
                {modelInputRows.map((currRow, index) => {
                  const label = currRow.label;
                  const value = currRow.value;
                  const inputType = currRow.inputType;
                  return (
                    <tr key={index} className="border">
                      <td className="font-semibold px-4 py-3">{label}</td>
                      {inputScenarios.map((scenario, idx) => (
                        <td key={idx} className="px-4">
                          {inputType === "text" && (
                            <>
                              {!isExport ? (
                                <input
                                  className="text-center outline-none border-b-2 border-gray-400 p-2 focus:outline-none focus:border-primary w-36"
                                  type="text"
                                  name={value}
                                  value={modelInputs[idx][value] || ""}
                                  onChange={(e) =>
                                    handleDescriptionChange(e, idx)
                                  }
                                  autoComplete="off"
                                />
                              ) : (
                                <p className="text-center outline-none border-none border-gray-400 p-2 focus:outline-none focus:border-primary w-36">
                                  {modelInputs[idx][value]}
                                </p>
                              )}
                            </>
                          )}
                          {inputType === "percentage" && (
                            <>
                              {!isExport ? (
                                <input
                                  className="text-center border-b-2 border-gray-400 p-2 focus:outline-none focus:border-primary w-36"
                                  type="text"
                                  name={value}
                                  id={idx}
                                  value={
                                    (modelInputs[idx][value] &&
                                      modelInputs[idx][value] + "%") ||
                                    (modelInputs[idx][value] === 0 ? "0%" : "")
                                  }
                                  onChange={(e) =>
                                    handlePercentageInputChange(e, idx)
                                  }
                                  onKeyDown={(e) =>
                                    handleBackspaceForPercentage(e, idx)
                                  }
                                  autoComplete="off"
                                />
                              ) : (
                                <p className="text-center outline-none border-none border-gray-400 p-2 focus:outline-none focus:border-primary w-36">
                                  {PercentFormatter2(
                                    modelInputs[idx][value],
                                    2
                                  )}
                                </p>
                              )}
                            </>
                          )}
                          {inputType === "currency" && (
                            <>
                              {!isExport ? (
                                <input
                                  className="text-center border-b-2 border-gray-400 p-2 focus:outline-none focus:border-primary w-36"
                                  type="text"
                                  name={value}
                                  id={idx}
                                  value={
                                    (modelInputs[idx][value] &&
                                      "$" + modelInputs[idx][value]) ||
                                    "$0"
                                  }
                                  onChange={(e) => handleCurrencyChange(e, idx)}
                                  autoComplete="off"
                                />
                              ) : (
                                <p className="text-center outline-none border-none border-gray-400 p-2 focus:outline-none focus:border-primary w-36">
                                  {CurrencyFormatter(
                                    modelInputs[idx][value],
                                    2
                                  )}
                                </p>
                              )}
                            </>
                          )}
                          {inputType === "number" && (
                            <>
                              {!isExport ? (
                                <input
                                  className="text-center border-b-2 border-gray-400 p-2 focus:outline-none focus:border-primary w-36"
                                  type="text"
                                  name={value}
                                  id={idx}
                                  value={
                                    (modelInputs[idx][value] &&
                                      modelInputs[idx][value]) ||
                                    0
                                  }
                                  onChange={(e) =>
                                    handleNumberInputChange(e, idx)
                                  }
                                  autoComplete="off"
                                />
                              ) : (
                                <p className="text-center outline-none border-none border-gray-400 p-2 focus:outline-none focus:border-primary w-36">
                                  {modelInputs[idx][value]}
                                </p>
                              )}
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Actions */}
        <div className="flex justify-end gap-5 select-none relative">
          <div className="flex justify-end gap-5 my-5 select-none mr-4">
            {load ? (
              <span className="flex items-center justify-center gap-2 px-5 text-center py-2 rounded-md bg-gray-600 cursor-pointer text-white">
                <LuLoader2 className="text-xl animate-spin" /> Processing
              </span>
            ) : (
              <input
                type="submit"
                onClick={(e) => submitForm(e)}
                value={"Submit"}
                className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white bg-primary ${"cursor-pointer"}`}
              />
            )}
            <div
              onClick={() => {
                if (showOutput) {
                  setSidebar("output");
                  setCurrentOutputTab("Scenario");
                  setCurrentScenarioIndex(1);
                  setOutputTypeToggle("poolValue");
                } else {
                  return null;
                }
              }}
              className={`flex items-center justify-center gap-2 w-32 px-5 py-2 rounded-md text-white ${
                showOutput ? "bg-orange-500 cursor-pointer" : "bg-orange-200"
              }`}
            >
              Show Output
            </div>
            {showOutput && (
              <div
                onClick={() => {
                  setIsOpen(true);
                }}
                className={`flex items-center justify-center gap-2 mr-4 px-5 py-2 rounded-md text-white ${"bg-blue-800 cursor-pointer"}`}
              >
                Show Units
              </div>
            )}
          </div>
        </div>

        {showError && (
          <div className="flex justify-end mb-4 -mt-2 mr-4">
            <p className="text-xs text-red-500">{showError}</p>
          </div>
        )}
      </div>

      {showOutput && (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <div className="px-2">
            <h2 className="font-bold">C Pool Units</h2>
            <div className="w-full my-2 border px-2 py-2">
              <table className=" text-xs w-full">
                <thead className="bg-gray-400">
                  <th className="text-left px-2">Elements</th>
                  {inputScenarios.map((scenario, index) => (
                    <th key={index} className="">
                      <div className="py-1">
                        <p>{scenario}</p>
                        <div className="flex gap-x-8 py-1 justify-center">
                          <p>Amount</p>
                          <p>Units</p>
                        </div>
                      </div>
                    </th>
                  ))}
                </thead>
                <tbody>
                  {modelInputRows.slice(10, 14).map((row, index) => (
                    <tr key={index} className="py-2 border">
                      <td className="py-2 px-2 border-r">{row.label}</td>
                      {inputScenarios.map((scenario, idx) => (
                        <td key={idx} className="border-r">
                          <div className="flex gap-x-6 justify-center">
                            <p className="mr-6">
                              ${NumberFormatter(modelInputs[idx][row.value], 2)}
                            </p>
                            <p>
                              {NumberFormatter(
                                allOutputScenario[idx]["optimized_volumes"][
                                  index
                                ],
                                4
                              )}
                            </p>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      )}
      {/*  */}
    </>
  );
};

export default InputScreen;
