// ***************************************************************   All Icons Import  ***************************************************************
import { dashboards } from "./dashboards";
import { GoHomeFill } from "react-icons/go";
import { RiDashboard3Line } from "react-icons/ri";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { FaChartLine, FaChartSimple, FaGears } from "react-icons/fa6";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";

// ***************************************************************   All Industries  ***************************************************************
export const deployment2024 = {
  title: "2024 Deployment",
  slug: "/2024-deployment",
  name: "deployment2024",
};

// User permissions
export const EquityAllowedUsers = ['mark.hammond', 'nirav.dagli', 'laik.shaikh']

// ***************************************************************   All Solutions  ***************************************************************
// CFO
export const CFO = {
  title: "Business Dashboards",
  // slug: "#",
  slug: "/business-dashboard",
  icon: <MdOutlineSpaceDashboard />,
  deployments: [deployment2024],
  deployment2024: [
    {
      title: "Master Dashboard",
      // slug: "#",
      slug: "/master-dashboard",
      icon: <LuLayoutDashboard />,
      dashboards: { ...dashboards.businessDashboards.masterDashboard },
    },
    {
      title: "Performance Dashboard",
      // slug: "#",
      slug: "/performance-dashboard",
      icon: <FaChartSimple />,
      dashboards: { ...dashboards.businessDashboards.performanceDashboard },
    },
    // {
    //   title: "4Qast & Variance",
    //   // slug: "#",
    //   slug: "/4qast-and-variance",
    //   icon: <FaChartLine />,
    //   dashboards: { ...dashboards.businessDashboards.foreqastTracking },
    // },
    // {
    //   title: "Summary",
    //   // slug: "#",
    //   slug: "/summary",
    //   icon: <RiDashboard3Line />,
    //   dashboards: { ...dashboards.businessDashboards.metricSummary },
    // },
    // {
    //   title: "Revenue Drill Down",
    //   // slug: "#",
    //   slug: "/revenue-drill-down",
    //   icon: <GiReceiveMoney />,
    //   dashboards: { ...dashboards.businessDashboards.revenueDrillDown },
    // },
    // {
    //   title: "Expense Drill Down",
    //   // slug: "#",
    //   slug: "/expense-drill-down",
    //   icon: <GiPayMoney />,
    //   dashboards: { ...dashboards.businessDashboards.expenseDrillDown },
    // },
    // {
    //   title: "Master Dashboard",
    //   // slug: "#",
    //   slug: "/master-dashboard",
    //   icon: <LuLayoutDashboard />,
    //   dashboards: { ...dashboards.businessDashboards.masterDashboard },
    // },
  ],
};

export const equityModel = {
  title: "Models",
  slug: "#",
  // slug: "/models",
  icon: <FaGears />,
  deployments: [deployment2024],
  deployment2024: [
    {
      title: "Equity Model",
      // slug: "#",
      slug: "/equity-model",
      icon: <FaGears />,
    },
  ],
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const home = [CFO];
export const models = [equityModel.deployment2024[0]];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...home,
];
